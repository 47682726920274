<template>
  <div id="app">
    <header>
      <div class="header-inner">
        <div class="logo-holder">
          <router-link to="/">
            <img
              src="./assets/images/bisa-consult-sample-logo-negative.png"
              width="30px"
              alt="Logo bisa consult"
            />
            <h1>BISA CONSULT SA</h1>
            <h2>{{ $t('Bureau dingénieurs en génie civil') }}</h2>
          </router-link>
        </div>
        <div class="nav-button-holder">
          <div class="nav-button vis-m">
            <span></span>
            <span></span>
            <span></span>
          </div>
        </div>
        <div class="nav-holder">
          <nav>
            <ul id="menu">
              <li>
                <router-link
                  :to="{ name: 'home', params: {lang: $i18n.locale }}"
                  :class="($route.name == 'home') ? 'act-link' : ''"
                >{{ $t('Accueil') }}</router-link>
              </li>
              <li>
                <router-link
                  :to="{ name: 'services', params: {lang: $i18n.locale }}"
                  :class="($route.name == 'services') ? 'act-link' : ''"
                >{{ $t('Services') }}</router-link>
              </li>
              <li>
                <router-link
                  :to="{ name: 'contact', params: {lang: $i18n.locale }}"
                  :class="($route.name == 'contact') ? 'act-link' : ''"
                >{{ $t('Contact') }}</router-link>
              </li>
            </ul>
          </nav>

          <div id="mobile-languages">
            <span @click="setLocale('fr')" :class="($i18n.locale == 'fr') ? 'selected' : ''">FR</span> |
            <span @click="setLocale('en')" :class="($i18n.locale == 'en') ? 'selected' : ''">EN</span>
          </div>
        </div>
      </div>
      <div class="selectMe">
        <span @click="setLocale('fr')" :class="($i18n.locale == 'fr') ? 'selected' : ''">FR</span> |
        <span @click="setLocale('en')" :class="($i18n.locale == 'en') ? 'selected' : ''">EN</span>
      </div>
    </header>

    <div id="wrapper">
      <router-view></router-view>
    </div>
  </div>
</template>

<script>
export default {
  methods: {
    setLocale(locale) {
      this.$i18n.locale = locale

      if (this.$route.params.lang !== locale) {
        this.$router.push({
          name: this.$route.name, 
          params: {
            lang: locale
          }
        });
      }
    }
  }
}
</script>