import $ from 'jquery'

$(document).ready(function() {
    var nb = $(".nav-button"), nh = $(".nav-holder");

    /**
     * Mobile menu
     */
    function showMenu() {
        nb.removeClass("vis-m");
        nh.slideDown(500);
    }

    function hideMenu() {
        nb.addClass("vis-m");
        nh.slideUp(500);
    }

    nb.click(function() {
        if ($(this).hasClass("vis-m")) showMenu(); else hideMenu();
    });
});

$(window).resize(function() {
    $(" .fullheight-carousel .item").css({
        height: $(".fullheight-carousel").outerHeight(true)
    });
    $(".height-emulator").css({
        height: $("footer").outerHeight(true)
    });

    var b = $(window).width();
    if (b > 1024) {
        $(".nav-holder").css({
            display: "block"
        });
    }
});