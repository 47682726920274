<template>
  <div class="content-holder elem transition3">
    <div class="content">
      <div class="bg-animate">
        <img src="../assets/images/body-bg.png" class="respimg" alt />
      </div>
      <div class="wrapper-inner">
        <div class="container">
          <div class="page-title no-border">
            <h2 v-html="$t('UN PROJET ? CONTACTEZ-NOUS')"></h2>
          </div>

          <section class="no-border">
            <div class="map-box">
              <iframe
                src="https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d5522.328448014693!2d6.146308!3d46.207188!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x478c65261dda292f%3A0xab166843c44c5242!2sRue%20Arnold%20Winkelried%206%2C%201201%20Gen%C3%A8ve%2C%20Suisse!5e0!3m2!1sfr!2scm!4v1591315446767!5m2!1sfr!2scm"
                width="100%"
                height="450"
                frameborder="0"
                style="border:0;"
                allowfullscreen
                aria-hidden="false"
                tabindex="0"
              ></iframe>
            </div>
          </section>

          <section class="no-border">
            <div class="contact-details">
              <div class="row">
                <div class="col-md-3">
                  <h4>{{ $t('Nos bureaux') }}</h4>
                  <ul>
                    <li>
                      <a href="#">{{ $t('Boulevard Georges-Favon 24') }}</a>
                    </li>
                    <li>
                      <a href="#">{{ $t('1204 Genève') }}</a>
                    </li>
                    <li>
                      <a href="#">{{ $t('Suisse') }}</a>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </section>

          <section>
            <div class="contact-form-holder">
              <div id="contact-form">
                <!-- alert error -->
                <div id="message" class="text-red-500" v-if="(formResponse.errors && formResponse.errors['fields']) || (formResponse.errors && formResponse.errors['captcha']) || (formResponse.errors && formResponse.errors['errOnSend'])">
                  <p v-if="formResponse.errors['fields']">{{ $t(formResponse.errors['fields']) }}</p>
                  <p v-if="formResponse.errors['captcha']">{{ $t(formResponse.errors['captcha']) }}</p>
                  <p v-if="formResponse.errors['errOnSend']">{{ $t(formResponse.errors['errOnSend']) }}</p>
                </div>

                <!-- alert success -->
                <div id="message" class="text-green-500" v-if="formResponse.success && formResponse.success['message']">
                    <p style="font-weight:600;">{{ $t('Merci !') }}</p>
                    <p>{{ $t(formResponse.success['message']) }}</p>
                </div>

                <form @submit.prevent="submit" name="contactform" id="contactform">
                  <input
                    v-model="contact.fullname" 
                    type="text"
                    id="name"
                    :placeholder="$t('Nom/Prénom') + '*'"
                  />
                  <p class="text-red-500 error-msg" v-if="formResponse.errors && formResponse.errors['fullname']">{{ $t(formResponse.errors['fullname']) }}</p>

                  <input
                    v-model="contact.email" 
                    type="text"
                    id="email"
                    :placeholder="$t('E-mail') + '*'"
                  />
                  <p class="text-red-500 error-msg" v-if="formResponse.errors && formResponse.errors['email']">{{ $t(formResponse.errors['email']) }}</p>

                  <input
                    type="tel"
                    v-model="contact.phoneNumber"
                    id="phone"
                    :placeholder="$t('Téléphone') + '*'"
                  />
                  <p class="text-red-500 error-msg" v-if="formResponse.errors && formResponse.errors['phoneNumber']">{{ $t(formResponse.errors['phoneNumber']) }}</p>

                  <textarea
                    v-model="contact.message"
                    id="comments"
                    :placeholder="$t('Message') + '*'"
                  ></textarea>
                  <p class="text-red-500 error-msg" v-if="formResponse.errors && formResponse.errors['message']">{{ $t(formResponse.errors['message']) }}</p>

                  <div class="recaptcha-style">
                    <vue-recaptcha
                      ref="recaptcha"
                      @verify="onCaptchaVerified"
                      @expired="onCaptchaExpired"
                      :sitekey="sitekey"
                    >
                    </vue-recaptcha>
                  </div>

                  <div>
                    <button type="submit" id="submit">
                      <span>{{ $t('Envoyer') }}</span>
                      <i class="fa fa-long-arrow-right"></i>
                    </button>
                  </div>
                </form>
              </div>
            </div>
          </section>
        </div>
      </div>

      <app-footer></app-footer>
    </div>
  </div>
</template>

<script>
import Footer from '../components/Footer'
import VueRecaptcha from 'vue-recaptcha';

export default {
  metaInfo() {
    return {
      title: 'BISA CONSULT SA - ' + this.$i18n.t('Contact'),
      meta: [
        { name: 'description', content: this.$i18n.t('contact_meta_description') },
        // Facebook
        { property: 'og:url', content: this.app_url + '/', vmid: 'og:url' },
        { property: 'og:type', content: 'website', vmid: 'og:type' },
        { property: 'og:title', content: 'BISA CONSULT SA - ' + this.$i18n.t('Contact'), vmid: 'og:title' },
        { property: 'og:description', content: this.$i18n.t('contact_meta_description'), vmid: 'og:description' },
        { property: 'og:image', content: this.app_url + (this.$route.params.lang === 'fr') ? '/og-share_fr.png' : '/og-share_en.png', vmid: 'og:image' },
        // Twitter
        { property: 'twitter:url', content: this.app_url + '/', vmid: 'twitter:url' },
        { name: 'twitter:title', content: 'BISA CONSULT SA - ' + this.$i18n.t('Contact'), vmid: 'twitter:title' },
        { name: 'twitter:description', content: this.$i18n.t('contact_meta_description'), vmid: 'twitter:description' },
        { name: 'twitter:card', content: 'summary_large_image', vmid: 'twitter:card' },
        { name: 'twitter:image', content: this.app_url + (this.$route.params.lang === 'fr') ? '/og-share_fr.png' : '/og-share_en.png', vmid: 'twitter:image' },
      ]
    }
  },
  data() {
    return {
      sitekey: '6LeoNMoZAAAAAKniJKkbGtNC0VO3dmFl-z0nEp2p',
      contact: {
        fullname: '',
        email: '',
        phoneNumber: '',
        message: '',
      },
      formResponse: {
        errors: false
      },
      recaptchaToken: '',
      app_url: process.env.VUE_APP_API,
    }
  },
  methods: {
    submit() {
      console.log(process.env.VUE_APP_API)
      this.$refs.recaptcha.reset();

      if (this.recaptchaToken.length > 0) {
        fetch(process.env.VUE_APP_API + '/ContactController.php', {
          method: "POST",
          headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json'
          },
          body:  JSON.stringify({
            contact: this.contact, 
            recaptchaToken: this.recaptchaToken
          })
        })
          .then(res => res.json())
          .then(data => {
            //refresh form values
            if(!data.errors) {
              this.contact = {};
              this.recaptchaToken = '';
            }
  
            this.formResponse = data;
          })
      }
    },
    onCaptchaVerified: function (recaptchaToken) {
      this.recaptchaToken = recaptchaToken;
    },
    onCaptchaExpired: function () {
      this.$refs.recaptcha.reset();
    },
    recaptchaResponsive: function() {
      // Make recaptcha responsive on screen < 500 px (mobile)
      let selector = document.querySelector('.recaptcha-style');

      if (selector.length > 0) {
          if (window.matchMedia("(min-width: 500px)").matches) {
              selector.classList.remove('recaptcha-responsive');
          } else {
              selector.children[0].className += 'recaptcha-responsive';
          }
      }
    }
  },
  mounted() {
    let recaptchaScript = document.createElement('script')
    recaptchaScript.setAttribute('src', 'https://www.google.com/recaptcha/api.js?onload=vueRecaptchaApiLoaded')
    document.head.appendChild(recaptchaScript)

    window.onload = () => {
      this.recaptchaResponsive();
    };

    window.onresize = () => {
      this.recaptchaResponsive();
    };
  },
  components: {
    'vue-recaptcha': VueRecaptcha,
    "app-footer": Footer
  }
};
</script>

<style scoped>
  .wrapper-inner {
    width: 100%;
    padding: 90px 0!important;
  }
</style>