<template>
  <footer>
    <div class="footer-inner">
      <div class="row">
        <div class="col-md-5">
          <router-link to="/" class="footer-logo">
            <img src="../assets/images/bisa-consult-sample-logo.png" width="50" alt />
          </router-link>
        </div>
        <div class="col-md-3">
          <div class="footer-adress">
            <span>{{ $t('Boulevard Georges-Favon 24') }}</span>
            <br />
            <span>{{ $t('1204 Genève') }}</span>
            <br />
            <span>{{ $t('Suisse') }}</span>
            <br />
            <a href="mailto:contact@bisa-consult.com">contact@bisa-consult.com</a>
            <a
              href="https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d5522.328448014693!2d6.146308!3d46.207188!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x478c65261dda292f%3A0xab166843c44c5242!2sRue%20Arnold%20Winkelried%206%2C%201201%20Gen%C3%A8ve%2C%20Suisse!5e0!3m2!1sfr!2scm!4v1591315446767!5m2!1sfr!2scm"
              target="_blank"
            >{{$t('Voir sur la carte')}}</a>
          </div>
        </div>
      </div>
      <div class="row">
        <div class="col-md-5"></div>
        <div class="col-md-7">
          <p>
            &#169; Bisa Consult SA {{ new Date().getFullYear() }}.
            <span
              v-html="$t('Tous droits réservés.')"
            ></span>
          </p>
        </div>
      </div>
    </div>
    <span class="footer-decor"></span>
  </footer>
</template>

<script>
import $ from 'jquery'

export default {
  mounted() {
    $(".height-emulator").css({
      height: $("footer").outerHeight(true)
    });
  }
};
</script>

<style>
.footer-adress {
  padding: 60px 0;
}
</style>