<template>
  <div class="content-holder elem transition3">
    <div class="content">
      <div class="bg-animate">
        <img src="../assets/images/body-bg.png" class="respimg" alt />
      </div>
      <div class="wrapper-inner">
        <div class="container">
          <div class="page-title no-border">
            <h2 v-html="$t('NOS PRESTATIONS')"></h2>
          </div>

          <section id="sec4">
            <div class="row">
              <!-- services 1 -->
              <div class="col-md-4">
                <div class="services-box box-item">
                  <img src="../assets/images/services/civil-engineering-office.jpg" alt class="respimg" />
                  <div class="services-info">
                    <h4>{{ $t('Bureau dingénieurs en génie civil') }}</h4>
                  </div>
                </div>
              </div>
              <!-- services 2 -->
              <div class="col-md-4">
                <div class="services-box">
                  <div class="services-image box-item">
                    <img src="../assets/images/services/direction-supervision.jpg" alt class="respimg" />
                  </div>
                  <div class="services-info">
                    <h4>{{ $t('Direction et supervision de l’exécution des travaux') }}</h4>
                  </div>
                </div>
              </div>
              <!-- services 3 -->
              <div class="col-md-4">
                <div class="services-box">
                  <div class="services-image box-item">
                    <img src="../assets/images/services/dams-and-hydraulics.jpg" alt class="respimg" />
                  </div>
                  <div class="services-info">
                    <h4>{{ $t('Barrages et hydraulique') }}</h4>
                  </div>
                </div>
              </div>
              <!-- services 4 -->
              <div class="col-md-4">
                <div class="services-box">
                  <div class="services-image box-item">
                    <img src="../assets/images/services/infrastructures-facilities.jpg" alt class="respimg" />
                  </div>
                  <div class="services-info">
                    <h4>{{ $t('Infrastructures, Aménagements') }}</h4>
                  </div>
                </div>
              </div>

            </div>
          </section>
        </div>
      </div>

      <app-footer></app-footer>
    </div>
  </div>
</template>

<script>
import Footer from '../components/Footer'

export default {
  components: {
    "app-footer": Footer
  },
  data () {
    return {
        app_url: process.env.VUE_APP_API,
    }
  },
  metaInfo() {
    return {
      title: 'BISA CONSULT SA - ' + this.$i18n.t('Services'),
      meta: [
        { name: 'description', content: this.$i18n.t('services_meta_description') },
        // Facebook
        { property: 'og:url', content: this.app_url + '/', vmid: 'og:url' },
        { property: 'og:type', content: 'website', vmid: 'og:type' },
        { property: 'og:title', content: 'BISA CONSULT SA - ' + this.$i18n.t('Services'), vmid: 'og:title' },
        { property: 'og:description', content: this.$i18n.t('services_meta_description'), vmid: 'og:description' },
        { property: 'og:image', content: this.app_url + (this.$route.params.lang === 'fr') ? '/og-share_fr.png' : '/og-share_en.png', vmid: 'og:image' },
        // Twitter
        { property: 'twitter:url', content: this.app_url + '/', vmid: 'twitter:url' },
        { name: 'twitter:title', content: 'BISA CONSULT SA - ' + this.$i18n.t('Services'), vmid: 'twitter:title' },
        { name: 'twitter:description', content: this.$i18n.t('services_meta_description'), vmid: 'twitter:description' },
        { name: 'twitter:card', content: 'summary_large_image', vmid: 'twitter:card' },
        { name: 'twitter:image', content: this.app_url + (this.$route.params.lang === 'fr') ? '/og-share_fr.png' : '/og-share_en.png', vmid: 'twitter:image' },
      ]
    }
  }
};
</script>

<style scoped>
.wrapper-inner {
  width: 100%;
  padding: 90px 0 !important;
}

.services-info {
  min-height: 139px;
}
</style>