<template>
  <div class="content-holder elem transition3">
    <div class="content">
      <div id="featured-bg-home"></div>
      <div class="wrapper-inner">
        <section class="no-padding no-border" id="sec1">
          <div class="container">
            <div class="page-title no-border">
              <h2 v-html="$t('Nous sommes un bureau dingénieurs spécialisé en génie civil')"></h2>
            </div>
          </div>
        </section>
        <div class="container">
          <section>
            <div class="row">
              <div id="about-us--container" class="col-md-12">
                <p
                  v-html="$t('BISA CONSULT SA est un bureau d’ingénieurs en génie civil établi à Genève, spécialisé dans l’étude de projets, la direction et la supervision de l’exécution des travaux.')"
                ></p>
                <p
                  v-html="$t('BISA CONSULT SA offre des prestations dans les principaux domaines de la construction avec un focus particulier sur les projets de construction routière, les équipements routiers, la sécurité routière et la gestion de l’entretien des chaussées ainsi que les projets ayant trait aux coursd’eau et les projets de construction environnementaux.')"
                ></p>
                <p
                  v-html="$t('BISA CONSULT SA œuvre à l’international, notamment sur le continent africain, et dispose d’experts dans les différents domaines spécifiques, ce qui lui permet d’intervenir de façon globale dans tout projet de construction.')"
                ></p>
              </div>
            </div>
          </section>
        </div>
      </div>
      
      <app-footer></app-footer>
    </div>
  </div>
</template>

<script>
import Footer from '../components/Footer'

export default {
  components: {
    'app-footer' : Footer,
  },
  data () {
    return {
        app_url: process.env.VUE_APP_API,
    }
  },
  metaInfo() {
    return {
      title: 'BISA CONSULT SA - ' + this.$i18n.t('Accueil'),
      meta: [
        { name: 'description', content: this.$i18n.t('home_meta_description') },
        // Facebook
        { property: 'og:url', content: this.app_url + '/', vmid: 'og:url' },
        { property: 'og:type', content: 'website', vmid: 'og:type' },
        { property: 'og:title', content: 'BISA CONSULT SA - ' + this.$i18n.t('Accueil'), vmid: 'og:title' },
        { property: 'og:description', content: this.$i18n.t('home_meta_description'), vmid: 'og:description' },
        { property: 'og:image', content: this.app_url + (this.$route.params.lang === 'fr') ? '/og-share_fr.png' : '/og-share_en.png', vmid: 'og:image' },
        // Twitter
        { property: 'twitter:url', content: this.app_url + '/', vmid: 'twitter:url' },
        { name: 'twitter:title', content: 'BISA CONSULT SA - ' + this.$i18n.t('Accueil'), vmid: 'twitter:title' },
        { name: 'twitter:description', content: this.$i18n.t('home_meta_description'), vmid: 'twitter:description' },
        { name: 'twitter:card', content: 'summary_large_image', vmid: 'twitter:card' },
        { name: 'twitter:image', content: this.app_url + (this.$route.params.lang === 'fr') ? '/og-share_fr.png' : '/og-share_en.png', vmid: 'twitter:image' },
      ]
    }
  }
};
</script>

<style scoped>
.wrapper-inner {
  width: 100%;
}

#featured-bg-home {
  background: url('../assets/images/backgrounds/bg-home.jpg');
  width: 100%;
  height: 550px;
  background-size: cover;
  background-position: right;
}

/* Media queries */
@media only screen and (min-width: 1036px) {
  .wrapper-inner {
    padding: 30px 0!important;
  }
}

@media only screen and (max-width: 600px) {
  #featured-bg-home {
    display: none;
  }
}
</style>