import Vue from 'vue'
import VueRouter from 'vue-router'

// views
import Home from '../views/Home.vue'
import Services from '../views/Services.vue'
import Contact from '../views/Contact.vue'

Vue.use(VueRouter)

const routes = [
  { path: '/', redirect: '/fr' },
  { path: '/:lang', name: 'home', component: Home },
  { path: '/:lang/services', name: 'services', component: Services },
  { path: '/:lang/contact', name: 'contact', component: Contact }
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

export default router
