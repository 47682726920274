import Vue from 'vue'
import App from './App.vue'
import router from './router'
import VueMeta from 'vue-meta'
import VueI18n from 'vue-i18n'
import { languages } from './locales/index.js'
import { defaultLocale } from './locales/index.js'

Vue.config.productionTip = false

// assets
import '@/assets/js/menu.js'
import '@/assets/css/reset.css'
import '@/assets/css/plugins.css'
import '@/assets/css/style.css'

// Vue uses
Vue.use(VueMeta)
Vue.use(VueI18n)

// Setup Vue i18n
const messages = Object.assign(languages)
const i18n = new VueI18n({
  locale: defaultLocale,
  fallbackLocale: 'fr',
  messages
})

/**
 * BeforeEach event
 */
router.beforeEach(function (to, from, next) {
  let lang = to.params.lang;
  if (!lang) {
    lang = 'fr';
  }

  i18n.locale = lang;
  next();
})

// Init vuejs
new Vue({
  router,
  i18n,
  render: h => h(App),
  mounted: () => document.dispatchEvent(new Event("x-app-rendered")),
}).$mount('#app')
